/* Establish projection conversion functions */
export var map = null;
var markerArray = [];
var markerVisibility = true;
const MAP_BOUNDS = new google.maps.LatLngBounds(
	new google.maps.LatLng(-30000 / 512, -30000 / 512), new google.maps.LatLng(30000 / 512, 30000 / 512));

function ProjectionCartesian() {};

ProjectionCartesian.prototype.fromLatLngToPoint = function(latLng) {
	return new google.maps.Point(latLng.lng() * 8, latLng.lat() * 8);
};

ProjectionCartesian.prototype.fromPointToLatLng = function(point, noWrap) {
	return new google.maps.LatLng(point.y / 8, point.x / 8, noWrap);
};

/* Init function called when the page body loads */
export function initialize() {
	const urlParams = new URLSearchParams(window.location.search);
	const urlX = urlParams.get('x');
	const urlZ = urlParams.get('z');
	const urlZoom = urlParams.get('zoom');

	var mapOptions = {
		center: new google.maps.LatLng(0, 0),
		zoom: 1,
		streetViewControl: false,
		fullscreenControl: false,
		gestureHandling: "greedy",
		mapTypeControl: false,
		zoomControl: false,
		mapTypeControlOptions: {
			mapTypeIds: ['overworld']
		},
	};

	if ((urlX !== null) && (urlZ !== null)) {
		mapOptions.center = new google.maps.LatLng(urlZ/512, urlX/512)
	}

	if ((urlZoom !== null)) {
		mapOptions.zoom = parseInt(urlZoom)
	}
	
	map = new google.maps.Map(document.getElementById('map_canvas'), mapOptions);
	
	var mapTypeOverworld = new google.maps.ImageMapType({
		getTileUrl: function(coord, zoom) {
			var z = zoom-6;
			var x = coord.x;
			var y = coord.y;

			var i = 6-zoom
			var t = Math.pow(2, i)
			var c = Math.floor(x - (720 / t))
			if ((z < 0 && (x > (100 / (2*i)))) || (z == 0 && (x > 100))) {
				x = c
			}
			return 'tiles/z' + z + '/' + x + ',' + y + '.png';
		},
		tileSize: new google.maps.Size(256, 256), // size of image.  their native size to display 1 to 1
		maxZoom: 6,
		minZoom: 1,
		name: 'Overworld'
	});

	// use the custom latitude and logitude projection
	mapTypeOverworld.projection = new ProjectionCartesian();

	// add the map type to the map
	map.mapTypes.set('overworld', mapTypeOverworld);
	map.setMapTypeId('overworld');
	
	// listener for clicks on the map surface
	google.maps.event.addListener(map, 'rightclick', function(event) {
		toggleAllMarkers();
	});

	//map.addListener("mousemove", (mapsMouseEvent) => {
	// or...
	map.addListener("click", (mapsMouseEvent) => {
		var x = Math.floor(mapsMouseEvent.latLng.lng() * 512)
    var y = Math.floor(mapsMouseEvent.latLng.lat() * 512)
    console.log(x + ", " + y)
  });
  
	map.addListener("center_changed", () => {
    updateURLParams()
    updateCenterText()
  });

	map.addListener("zoom_changed", () => {
    updateURLParams()
    updateCenterText()
  });

	map.addListener('dragend', () => {
    if (MAP_BOUNDS.contains(map.getCenter())) return;

    // We're out of bounds - Move the map back within the bounds
    var c = map.getCenter(),
    x = c.lng(),
    y = c.lat(),
    maxX = MAP_BOUNDS.getNorthEast().lng(),
    maxY = MAP_BOUNDS.getNorthEast().lat(),
    minX = MAP_BOUNDS.getSouthWest().lng(),
    minY = MAP_BOUNDS.getSouthWest().lat();

    if (x < minX) x = minX;
    if (x > maxX) x = maxX;
    if (y < minY) y = minY;
    if (y > maxY) y = maxY;

    map.setCenter(new google.maps.LatLng(y, x));
  });

	updateURLParams()
  updateCenterText()
}

function updateURLParams() {
	var x = Math.floor(map.center.lng() * 512)
  var z = Math.floor(map.center.lat() * 512)
  var zoom = map.zoom

	// Construct URLSearchParams object instance from current URL querystring.
	var queryParams = new URLSearchParams(window.location.search);

	// Set new or modify existing parameter value. 
	queryParams.set("x", x);
	queryParams.set("z", z);
	queryParams.set("zoom", zoom);

	// Replace current querystring with the new one.
	history.replaceState(null, null, "?"+queryParams.toString());
}

function updateCenterText() {
	var x = Math.floor(map.center.lng() * 512)
  var z = Math.floor(map.center.lat() * 512)
  var zoom = map.zoom

	$("#coords_move").html(x + ", " + z)
}

const infoWindowTemplate = "" +
'<div class="infoWindowContent">' +
'  <h2><img src="{{img}}" />{{name}}</h2>' +
'  <div>' +
'    <p><b>Coords: </b>{{x}}, {{z}}</p>' +
'    <p><b>Added: </b>{{date}}</p>' +
'  </div>' +
'</div>';


export function addImageMarker(title, x, y, image, priority, dateAdded) {
	var marker = new google.maps.Marker({
		position: new google.maps.LatLng(y / 512, x / 512),
		map: map,
		icon: image,
		title: title,
		dateAdded: dateAdded,
		x: x,
		z: y
	});
	marker.setZIndex(priority);

	marker.addListener("click", () => {
		var windowText = infoWindowTemplate;
		windowText = windowText.replace("{{name}}", marker.title)
		windowText = windowText.replace("{{x}}", marker.x)
		windowText = windowText.replace("{{z}}", marker.z)
		windowText = windowText.replace("{{date}}", marker.dateAdded)
		windowText = windowText.replace("{{img}}", marker.icon)

		const infowindow = new google.maps.InfoWindow({
    	content: windowText,
  	});

    infowindow.open(map, marker)
  });

	markerArray.push(marker);
}

export function toggleAllMarkers() {
	markerVisibility = !markerVisibility;
	for (var i in markerArray) {
		markerArray[i].setVisible(markerVisibility);
	}
}

export function panTo(x, z) {
	var latLng = new google.maps.LatLng(z / 512, x / 512); //Makes a latlng
  map.panTo(latLng);
}