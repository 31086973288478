// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const MarkerType = {
  "BASE": "BASE",
  "SPAWN": "SPAWN",
  "POI": "POI"
};

const { Pin } = initSchema(schema);

export {
  Pin,
  MarkerType
};