import Amplify, { API, graphqlOperation } from "aws-amplify";
import awsconfig from "./aws-exports";
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { Pin, MarkerType } from './models';
import * as swanmap from "./swanmap";

Amplify.configure(awsconfig);

async function getAllMarkers() {
  return await DataStore.query(Pin, Predicates.ALL, {
  	sort: s => s.name(SortDirection.DESCENDING)
	})
}

// async function clearLocalCache() {
// 	return await DataStore.clear();
// }

// ToDo: Enable live sync at some point
// const subscription = DataStore.observe(Pin).subscribe(msg => {
//   console.log(msg);
// });

function doGetAllMarkers() {
	getAllMarkers().then((items) => {
		if (items.length == 0) {
			setTimeout(function () {
				doGetAllMarkers()
			}, 500);
			return;
		}
		for (const p in items) {
			swanmap.addImageMarker(items[p].name, items[p].x, items[p].z, items[p].imgUrl, items[p].priority, items[p].dateAdded)
			var newPoi = makePOIRow(items[p].name, items[p].x, items[p].z, items[p].imgUrl)
			
			if (items[p].type == MarkerType.BASE) $("#list_bases").prepend(newPoi);
			if (items[p].type == MarkerType.POI) $("#list_pois").prepend(newPoi);
			if (items[p].type == MarkerType.SPAWN) $("#list_map").prepend(newPoi);
		}
	});	
}

$(document).ready(function () {
	$('div#map_canvas').height($(window).height());
	swanmap.initialize()
	doGetAllMarkers()
});

$(window).resize(function () {
	$('div#map_canvas').height($(window).height());
});

$("#right_box_handle").click(function () {
	var r = $('#right_box').css("right")
	if (r == "0px") {
		$('#right_box').animate({ right: "-221px"} , 400);
		$('#right_box_handle').animate({ right: "0"} , 400, function () {
			$(this).html("&lt;")
		});
	}
	if (r == "-221px"){
		$('#right_box').animate({ right: "0px"} , 400);
		$('#right_box_handle').animate({ right: "220"} , 400, function () {
			$(this).html("&gt;")
		});
	}
});

function makePOIRow(title, x, z, imgurl) {
  var poi = $(document.createElement('li')).addClass("poi").attr("data-x", x).attr("data-z", z);

	var poiimgspan = $(document.createElement('span')).addClass("poiimg").text(name);
  var poiimg = $(document.createElement('img')).attr("src", imgurl).appendTo(poiimgspan);
  poiimgspan.appendTo(poi)

  var poilink = $(document.createElement('span')).addClass("poitxt").text(title).appendTo(poi);

  poi.click(function (evt) {
  	var x = $(this).attr("data-x")
  	var z = $(this).attr("data-z")
  	swanmap.panTo(x, z)
	})
  return poi;
}
